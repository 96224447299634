import axios from "@/axios"


export async function getTaxList(params) {
    const url = `/tax/tenant-admin?${params}`
    return await axios.get(url)
}
export async function getClientList(tenantId) {
    const url = `/tenant/${tenantId}/client`
    return await axios.get(url)
}
export async function getTaxDetails(id) {
    const url = `/tax/tenant-admin/${id}`
    return await axios.get(url)
}

export async function saveTax(payload) {
    const url = `/tax/tenant-admin`
    return await axios.post(url, payload)
}

export async function editTaxDetails(payload) {
    const url = `tax/tenant-admin`
    return await axios.put(url,payload);
}

export async function getTenantTax(taxId) {
    const url = `/tax/tenant-admin-taxes/${taxId}`
    return await axios.get(url)
}

export async function assignTaxTenant(taxId, payload) {
    const url = `/tax/assign-client-taxes/${taxId}`
    return await axios.post(url , payload)
}


