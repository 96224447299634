<template>
    <div class="field-table flex-1 flex flex-col  max-h-full max-w-full  min-h-full text-base-content">
        <SubHeader :handleClick="handelAllocate" buttonText="Allocate Credits" :showBtn="true" class="mx-6 pr-6" :customCrumbLists="customCrumbLists"/>
        <div class="flex relative flex-1 flex-col flex-grow max-h-full max-w-full">
            <vue-good-table :columns="columns" :isLoading="loading" :rows="filteredRowsData" :pagination-options="{ enabled: true, mode: 'records'}" >
                <template #column-filter="{ column }">
                    <!-- <div v-if="column.filterOptions && column.filterOptions.enabled && column.config && column.config.filter_type === 'date'">
                        <DatePicker range v-model="date_range" @input="(value) => updateColumnFilters(column, value, updateFilters)" 
                        :key="date_range_key" class="w-full" format="DD MMM, YYYY" value-type="timestamp"  :placeholder="due_date" />
                    </div> -->
                    <dropdown
                        ref="setFilterRef"
                        reposition
                        searchable
                        class="filter-dropdown"
                        placeholder="Select"
                        v-if="column?.filterOptions && column?.filterOptions?.enabled"
                        :options="getFilterOptions(column)"
                        @search="searchFilter(column, $event)"
                        @input="(value) => updateColumnFilters(column, value)"
                        :value="filters[column.field]"
                        :limit="1"
                        maxWidth="100%"
                        width="100px"
                        minWidth="100%"
                        :config="{ label: 'name', trackBy: 'id' }"
                        :multiple="false"
                        :taggable="false"
                    >
                </dropdown>
                
                </template>

                
                <template slot="table-row" slot-scope="props">
                    <div v-if="props.column.field === 'unique_id'"
                        :class="[props.row.system_default && 'default hover-text']" class="flex gap-3">
                        <router-link :to="{
                                name: 'allotedcredit',
                                params: {id: props.row.credit_pack_id}
                            }" class="text-primary whitespace-nowrap cursor-pointer hover:text-dvbrandhoveron">
                                {{ props.row.unique_id}}
                        </router-link>
                    </div>
                    <div v-if="props.column.field == 'credit_pack_name'" class="flex justify-center">
                        {{props.row.credit_pack_name}}
                    </div>
                    <div v-if="props.column.field == 'users'" class="flex justify-center cursor-pointer" @click="assignedUsers(props.row.users)">
                        <div class="indicator">
                            <span class=" w-2 h-4 indicator-item badge badge-primary">{{props.row.number_of_users}}</span> 
                            <font-awesome-icon class="w-6  mx-2" icon= "user"/>
                        </div>
                    </div>
                    <div v-if="props.column.field == 'total_credits'" class="flex justify-center">
                        {{props.row.total_credits}}
                    </div>
                    <div v-if="props.column.field == 'validity'" class="flex justify-center">
                        {{props.row.validity}} Days
                    </div>
                    <div v-if="props.column.field == 'order_date'" class="flex justify-center">
                        {{ props.row.order_date ? FormatDateNew(new Date(props.row.order_date)) : '-' }}
                    </div>
                    <div v-if="props.column.field == 'activated_at'" class="flex justify-center">
                        {{ props.row.activated_at ? FormatDateNew(new Date(props.row.activated_at)) : '-' }}
                    </div>
                    <div v-if="props.column.field == 'allotment_date'" class="flex justify-center">
                        {{ props.row.allotment_date ? FormatDateNew(new Date(props.row.allotment_date)) : '-' }}
                    </div>
                    <div v-if="props.column.field == 'valid_till'" class="flex justify-center">
                        {{ props.row.valid_till ? FormatDateNew(new Date(props.row.valid_till)) : '-' }}
                    </div>
                    <div v-if="props.column.field == 'remaining_credits'" class="flex justify-center">
                        {{ props.row.remaining_credits }}
                    </div>
                    <div v-if="props.column.field == 'pack_type'" class="flex justify-center">
                        {{ props.row.pack_type }}
                    </div>
                    <div v-if="props.column.field == 'alloted_credits'" class="flex justify-center">
                        {{ props.row.alloted_credits }}
                    </div>
                    <div v-if="props.column.field == 'status'" class="flex justify-center capitalize">
                        <span :class="getStatusColor(props.row.status)">{{ props.row.status }}</span>
                    </div>
                </template>

                <template #loadingContent>
                    <div class="w-full py-10 justify-center flex">
                        <Loader />
                    </div>
                </template>
            </vue-good-table>
            <button 
                v-if="Object.values(filters).length"
                class=" cursor-pointer py-1 px-3 bg-gray-100 hover:bg-gray-200 rounded-md text-xs text-black flex justify-center items-center font-semibold absolute z-50 left-2" :class="isSelectionChanged ? 'top-14' : 'top-2' " @click="clearFilters">
                Clear filters
            </button>
            <Modal ref="assigned-users" title='Assigned Users' :showFooter='false'> 
                <template #content>
                    <div class="grid gap-2"> 
                        <span class="border-b text-bold" v-for="data in userLists" :key="data">{{data.user_name}}</span>
                    </div>
                </template>
            </Modal>
        </div>
    </div>
</template>
<script>
    import SubHeader from "@/components/SubHeader";
    import {getCreditPacksAllocationList} from "../services"
    import Loader from "@/components/loader";
    import Modal from "@/components/modal-content"
    import { FormatDateNew } from "@/plugins/functions"
    import dropdown from "@shared/components/dropdown-base";
    import { uuid } from "vue-uuid";

    export default {
        name: "credit-packs",
        title: "Credit Admin",
        components: {
            SubHeader,
            Loader,
            Modal,
            dropdown
        },
        data() {
            return {
                columns: [{
                    label: "Pack Name",
                    field: "credit_pack_name",
                    sortable: true,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "credit_pack_name",
                    },
                }, {
                    label: "Unique Id",
                    field: "unique_id",
                    sortable: true,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "unique_id",
                    },
                },{
                    label: "Credits",
                    field: "total_credits",
                    sortable: true,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "total_credits",
                    },
                }, {
                    label: "Validity",
                    field: "validity",
                    sortable: true,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "validity",
                    },
                }, {
                    label: "Order Date",
                    field: "order_date",
                    filter_type: "date",
                    sortable: true,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "order_date",
                    },
                }, {
                    label: "Allotment",
                    field: "allotment_date",
                    filter_type: "date",
                    sortable: true,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "allotment_date",
                    },
                },{
                    label: "Start Date",
                    field: "activated_at",
                    filter_type: "date",
                    sortable: true,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "activated_at",
                    },
                }, {
                    label: "Expiry Date",
                    field: "valid_till",
                    filter_type: "date",
                    sortable: true,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "valid_till",
                    },
                },{
                    label: "Assign To",
                    field: "users",
                    sortable: true,
                    // filterOptions: {
                    //     enabled: true, // enable filter for this column
                    //     multi: true,
                    //     isLoading: false,
                    //     query_key: "users",
                    // },
                },{
                    label: "Type",
                    field: "pack_type",
                    sortable: true,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "pack_type",
                    },
                },{
                    label: "Alloted",
                    field: "alloted_credits",
                    sortable: true,
                    filterOptions: {
                        enabled: true, // enable filter for this column
                        multi: true,
                        isLoading: false,
                        query_key: "alloted_credits",
                    },
                }, {
                    label: "Balance",
                    field: "remaining_credits",
                    sortable: true,
                    // filterOptions: {
                    //     enabled: true, // enable filter for this column
                    //     multi: true,
                    //     isLoading: false,
                    //     query_key: "remaining_credits",
                    // },
                }, {
                    label: "Status",
                    field: "status",
                    sortable: true,
                    // filterOptions: {
                    //     enabled: true, // enable filter for this column
                    //     multi: true,
                    //     isLoading: false,
                    //     query_key: "status",
                    // },
                },  ],
                rowsData: [],
                loading: false,
                userLists: [],
                customCrumbLists: [{name: 'Credit Allocation'}],
                filters: {},
                filteredRowsData: []
            }
        },
        async mounted() {
            this.loading = true
            await this.setCreditPacks()
            this.loading = false
            this.filteredRowsData = this.rowsData;
        },
        methods: {
            FormatDateNew,
            async setCreditPacks()
            {
                const {data} = await getCreditPacksAllocationList()
                this.rowsData = data
            },
            assignedUsers(users) {

                this.userLists = users
                this.$refs["assigned-users"].showModal();
            },
            async handelAllocate(){
                this.$router.push({
                    name: "Allocate Credits",
                });
            },
            getStatusColor(status) {
                if (status.toLowerCase() == "in progress") {
                    return "text-yellow-500"
                }
                if (status.toLowerCase() == 'active') {
                    return 'text-green-400'
                }
            },
            getFilterOptions(column){
                // get unique filter options array
                let filterData = []
                if (column?.field == 'users') {
                    this.rowsData.forEach((rowfilter) => {
                        rowfilter?.users?.map((rowData) => (
                            filterData?.push(
                                {
                                id: uuid.v4(),
                                name: rowData?.user_name
                                }
                            )
                        ))
                    })
                } else {
                    filterData = this.rowsData
                    .filter((row) => row[column.field])
                    .map((row) => (
                        { 
                            id: row.field_type_id ?? uuid.v4(), 
                            label: row[column.field],
                            name: column?.filter_type == 'date' ? FormatDateNew(new Date (row[column.field])) : row[column?.field]
                        }
                    )
                    )
                }
                if (filterData && filterData?.length > 0) {
                    const uniqueMap = new Map();
                    filterData = filterData.forEach(item => {
                      uniqueMap.set(item.name, item);
                    });
                    return Array.from(uniqueMap.values());
                }
                return filterData;
            },
            updateColumnFilters(column, value){
                if (column?.filter_type == 'date') {
                    this.filters[column.field]  =  value.label ? [value.label] : [] ;
                } else {
                    this.filters[column.field]  =  value.name ? [value.name] : [] ;
                }
                let result = this.rowsData;
                for (let filter in this.filters) {
                    if (this.filters[filter]?.length) {
                        result = result.filter((el) => this.filters[filter].includes(el[filter]));
                    }
                }
                this.filteredRowsData = result;
            },
            clearFilters() {
                this.filters = {};
                this.filteredRowsData = this.rowsData;
            },
        },
    }
</script>
<style>
    .filter-dropdown {
        box-shadow: none !important;
        @apply border-transparent border  bg-white;
    }
</style>
